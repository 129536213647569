<template>
  <form @submit.prevent="onSubmit">
    <div
      class="component-search-bar"
      :class="[
        { 'component-search-bar--big': bigSize },
        { 'component-search-bar--light-shadow': lightShadow }
      ]"
    >
      <div
        class="component-search-bar__item component-search-bar__item--keyword"
        :style="{ borderColor: borderColor }"
      >
        <b-form-input
          v-model="form.search"
          autocomplete="off"
          class="component-search-bar__item__input"
          :placeholder="searchPlaceholder"
        />
        <i class="component-search-bar__item__icon icon-search"></i>
      </div>

      <div
        :style="disableLocation ? { display: 'none' } : {}"
        class="component-search-bar__item component-search-bar__item--location"
      >
        <i class="component-search-bar__item__icon icon-location"></i>

        <FormInputAddressAutoComplete
          v-if="!modifiedAddressSearch"
          v-model="form.location"
          :placeholder="locationPlaceholder"
          :component-restrictions="componentRestrictions"
          type="(regions)"
          class="component-search-bar__item__address"
          input-class="form-control component-search-bar__item__input"
        ></FormInputAddressAutoComplete>

        <!-- <div style="display: flex;">
          <span>{{ selecetedCountryName }}</span>
          <span style="padding-left: 1rem;">V</span>
          <div
            style="position: absolute;top: 4rem;left: 6rem; color: black; border: 1px solid grey;"
          >
            <div
              style="padding: 0 1rem; display: flex; align-items: center; justify-content: flex-start; height: 2.5rem; width: 10rem; background-color:white"
              v-for="(country, i) in siteCountries"
              :key="i"
              @click="form.countryCode = country.value"
            >
              {{ country.text }}
            </div>
          </div>
        </div> -->

        <b-dropdown
          v-if="!disableCountrySelector"
          class="component-search-bar__item__country"
          variant="link"
          no-caret
          right
        >
          <template slot="button-content">
            <span>{{ selecetedCountryName }}</span>
            <i class="icon-caret text-blue-900"></i>
          </template>

          <b-dropdown-item
            v-for="(country, i) in sortedSiteCountries"
            :key="i"
            @click="form.countryCode = country.value"
          >
            {{ country.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-if="disableLocation" style="width: 1.25rem;"></div>

      <div class="component-search-bar__submit">
        <button class="component-search-bar__submit__button btn">
          Search
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import find from 'lodash/find';
import FormInputAddressAutoComplete from '@/components/form/FormInputAddressAutoComplete';
import { convertCountryCodeForMaps } from '@/services/GoogleMaps';
import { customCountrySort } from '~/helpers/helpers';

export default {
  name: 'SearchBar',
  components: {
    FormInputAddressAutoComplete
  },
  props: {
    value: {
      type: Object
    },
    fullCountryNames: Boolean,
    disableLocation: Boolean,
    disableAddressSearch: Boolean,
    disableCountrySelector: Boolean,
    includeRemoteInCountrySelector: {
      type: Boolean,
      default: false
    },
    autoSuggestCountryCodes: Array,
    searchPlaceholder: String,
    locationPlaceholder: {
      type: String,
      default: 'City or area'
    },
    borderColor: {
      type: String,
      default: '#f5f7fa'
    },
    bigSize: Boolean,
    lightShadow: Boolean,
    onSubmit: {
      type: Function,
      required: true
    }
  },
  data() {
    // console.log('this.value', this.value);
    return {
      form: {
        search: get(this.value, 'search'),
        location: get(this.value, 'location'),
        countryCode: get(this.value, 'countryCode')
      }
    };
  },
  computed: {
    modifiedAddressSearch() {
      if (!this.disableAddressSearch && this.form.countryCode === 'REMOTE') {
        return !this.disableAddressSearch;
      }
      return this.disableAddressSearch;
    },
    selecetedCountryName() {
      const country = find(this.siteCountries, {
        value: this.form.countryCode
      });

      if (!country) {
        return 'Country';
      }

      if (this.includeRemoteInCountrySelector && country.value === 'REMOTE') {
        return 'Remote jobs';
      }

      return !this.fullCountryNames ? this.form.countryCode : country.text;
    },
    siteCountries() {
      if (this.includeRemoteInCountrySelector) {
        return this.$store.getters['meta/siteCountriesInclRemoteExNI'];
      }

      // return this.$store.getters['meta/siteCountriesExNI'];
    },
    sortedSiteCountries() {
      return (this.$store.getters['meta/siteCountriesInclRemoteExNI'] || []).sort(
        customCountrySort
      );
    },
    componentRestrictions() {
      if (this.autoSuggestCountryCodes && this.autoSuggestCountryCodes.length) {
        return {
          country: this.autoSuggestCountryCodes.map((code) => convertCountryCodeForMaps(code))
        };
      }

      if (!this.disableCountrySelector && this.form.countryCode) {
        return {
          country: [convertCountryCodeForMaps(this.form.countryCode)]
        };
      }

      if (this.form.countryCode) {
      }

      return undefined;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.form = newValue;
      }
    },
    form: {
      deep: true,
      handler() {
        this.$emit('input', this.form);
      }
    },
    'form.country'() {
      this.form.location = undefined;
      // TODO: CHECK
      this.value.location = undefined;
    }
  }
};
</script>

<style lang="scss">
.component-search-bar {
  display: flex;
  align-items: center;
  background-color: $white;
  box-shadow: 0 30px 90px rgba(31, 36, 48, 0.1);

  $root: &;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    line-height: 1;
    height: rem(80px);
    padding-left: rem(62px);

    $item: &;

    &__input {
      border: none;
      color: $gray-900;
      line-height: 1;
      width: 100%;
      padding-right: rem(20px);
      background: transparent;

      &:focus,
      &:active {
        & + #{$item}__icon {
          color: $gray-900;
        }
      }
    }

    &__icon {
      color: $gray-600;
      position: absolute;
      top: 50%;
      left: rem(26px);
      font-size: rem(20px);
      transform: translateY(-50%);
      @include transition();
    }

    &--keyword {
      flex: 1 1 57%;
      border-right: 2px solid $border-color;
    }

    &--location {
      flex: 1 1 43%;
    }

    &__address {
      flex: 1 1 auto;
    }

    &__country {
      flex: 0 0 auto;
      padding: 0 rem(15px);
    }
  }

  &__submit {
    flex: 0 0 auto;
    padding: 0 rem(20px) 0 0;

    &__button {
      width: rem(160px);
      height: rem(56px);
      padding: 0;
    }
  }

  &--light-shadow {
    box-shadow: 0 30px 60px -30px rgba(31, 36, 48, 0.1) !important;
  }

  &--big {
    height: rem(110px);

    #{$root}__item {
      &__icon {
        font-size: rem(24px);
      }
    }

    #{$root}__submit {
      &__button {
        width: rem(190px);
        height: rem(80px);
      }
    }
  }

  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    &__item {
      flex: 1 1 auto;
      width: 100%;
      border: none;
      height: rem(56px);
      padding-left: 35px;

      &__input {
        height: rem(56px);
      }

      &__icon {
        left: 14px;
      }

      &:first-child {
        border-bottom: 1px solid $border-color;
      }
    }

    &__submit {
      width: 100%;
      padding: 0;

      &__button {
        display: block;
        width: 100% !important;
      }
    }

    &--big {
      #{$root}__item {
        height: rem(72px);

        &__icon {
          font-size: rem(24px);
        }
      }

      #{$root}__submit {
        &__button {
          width: rem(190px);
        }
      }
    }
  }
}
</style>
